import "../chunks/chunk.HAL7R4WT.js";
import {
  registerIconLibrary,
  unregisterIconLibrary
} from "../chunks/chunk.ZL53POKZ.js";
import "../chunks/chunk.P7ZG6EMR.js";
import "../chunks/chunk.3TFKS637.js";
import "../chunks/chunk.3Y6SB6QS.js";
import "../chunks/chunk.B3BW2AY6.js";
export {
  registerIconLibrary,
  unregisterIconLibrary
};
